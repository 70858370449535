@import "../../global.scss";

.contact{
    background-color: white;
    display:flex;

    @include mobile{
        flex-direction: column;
    }


    .left{
        flex: 1;
        overflow: hidden;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;

        @include mobile{
            flex: 1;
            margin-bottom: 30px;
        }

        ul{

        li{
            margin-top: 50px;
            margin-left: 100px;

            img{
                vertical-align: middle;
                width: 50px;
                height:50px;
                margin-right: 30px;

                @include mobile{
                    width: 30px;
                    height: 30px;
                    
                }
            }

            a{
            font-size: 30px;
            color:black;
            text-decoration: none;
            }

            @include mobile{
                font-size: 30px;
                margin-left:0;
            }

            &:hover{
                font-weight: 500;
            }
        }
    }

    }

    .right{
        flex:2.5;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        @include mobile{
            flex: 1.5;
        }

        h2{
            font-size: 50px;
        }
    
    form{
        width: 100%;
        height: 70%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        input{

           width:500px;
           height:70ox;
           font-size:14px;

           @include mobile{
               width: 200px;
               height: 20px;
           }
        }
        textarea{
            width:500px;
            height:250px;
            font-size: 14px;

            @include mobile{
                width: 200px;
                height: 100px;
            }
        }
        button{
            width:200px;
            height: 50px;
            color:white;
            background-color:purple;
            border: none;
            border-radius: 10px;
            font-weight: 800;
            font-size: 24px;

            &:focus{
                outline: none;
            }
       }
       span{
           color:green;
       }
    }
}

}