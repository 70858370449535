@import "../../global.scss";

.intro{
    background-color: white;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .left{
        flex: 0.5;
        overflow: hidden;
        margin: 125px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    

        @include mobile{
            flex:0.6;
            margin: 25px;
        }
        
        .imgContainer{
            width: 350px;
            height: 350px;
            background-color: blueviolet;
            border-radius: 5%;
            overflow: hidden;

            @include mobile{
                width: 75%;
                margin-left: 5%;
                margin-right: 5%;
            }

            img{
                height: 100%;
            }
        }
    }
    .right{
        flex: 0.5;
        position:relative;
        margin-right: 100px;

        @include mobile{
            margin-left: 30px;
            margin-right: 30px;
            align-items: center;
            justify-content: flex-start;
        }

        .wrapper{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;
                height: 80%;
            }

            h1{
                font-size: 60px;
                margin-top: 10px;

                @include mobile{
                    font-size: 30px;
                    margin-top: 5px;
                }
            }
            h2{
                font-size: 35px;
                @include mobile{
                    font-size: 20px;
                }
            }
            h4{
                font-size: 25px;
                margin-top: 20px;
                a{
                    font-size:25px;
                    text-decoration: none;
                    color:purple;

                    &:hover{
                        color:red;
                    }
                }
            }
            h3{
                font-size: 20px;

                @include mobile{
                    font-size: 0px;
                }

                span{
                    font-size: inherit;
                    color:gray
                }
            }
        }

        .ityped-cursor{
            animation: blink 1s infinite;
        }

        @keyframes blink{
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }

        .arrow{
        a{
            position: absolute;
            bottom:10px;
            img{
                width: 30px;
                animation: arrowBlink 2s infinite;
            }


            @keyframes arrowBlink{
                100%{
                    opacity: 0;
                }

            }
        }
    }
    }
}