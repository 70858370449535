@import "../../global.scss";

.about{

    height: 100%;
    width: 100%;   


    .background_videos{
        position: absolute;
        top: 100%;
        left: 0px;
        height: 100%;
        width: 100%;
        background-color: black;
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow-y:hidden;       
        z-index: 1;

        .left{
          flex: 3;
          filter: blur(7px);
        }
 
        .right{
          flex: 1;
          overflow-y:hidden; 
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .video_background{
            overflow-y:hidden; 
          }

          @include mobile{
            flex: 0;
            height: 0;
            width: 0;
          }
      }
   }

   .text-box{
        position: absolute;
        top: 105%;
        left: 7%;
        background-color: grey;
        opacity: 0.6;
        z-index: 2;  
        width: 63%;
        height: 90%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include mobile{
          width: 87%;
          height: 85%;
        }
   }
   .texts{
       position: absolute;
       top: 105%;
       left: 7%;
       z-index: 3;  
       width: 63%;
       height: 90%;
       display:flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       @include mobile{
        width: 87%;
        height: 85%;
       }
       
       .title{
          margin: 50px;
          overflow: hidden;

          @include mobile{
              margin: 20px;
              height: 70px;
          }

          h1{
            font-size: 50px;
            color: white;

            @include mobile{
              font-size: 30px;
          }
           }
        }
        
        .main{
            margin: 15px;
            h2{
            font-size: 18px;   
            color:white;
            font-style: normal;
            a{
                font-size:18px;
                text-decoration: none;
                font-style: bold;
                color:white;

                &:hover{
                    text-decoration:underline;
                }
              }
            }
        }
    }
    
    .arrow{
      a{
        position: absolute;
        top:195%;
        left: 35%;
        z-index: 4;  

          img{
              width: 30px;
              animation: arrowBlink 2s infinite;
          }

          @include mobile{
            top:193%;
            left: 49%;
          }

          @keyframes arrowBlink{
              100%{
                  opacity: 0;
              }

          }
      }
  }
}
