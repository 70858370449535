@import "../../global.scss";

.portfolio{
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .spin{
        position: absolute;
        top: 208%;

        @include mobile{
            top: 206%;
        }
    }

    h1{
        font-size: 50px;

        @include mobile{
            font-size: 30px;
            margin-top: 10px;
        }
    }

    ul{
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile{
            flex-wrap: wrap;
            justify-content: center;
            margin: 10px, 0;
        }
        
    }

    .container{
        width: 80%;
        height: 75%;
        margin-top: 10px;;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: absolute;
        top: 215%;
 
        @include mobile{
            width: 100%;
            top: 212%;
            height: 80%
        }
 
 
        .item{
            width: 400px;
            height: 200px;
            border-radius: 20px;
            border: 1px solid lightgrey;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            position: relative;
            transition: all .5s ease;
            cursor: pointer;
            object-fit: 'contain';
 
            @include mobile{
                width: 350px;
                height: 200px;
            }
 
            h3{
             position: absolute;
             font-size: 20px;
             }
 
             a{
                 height:100%;
                 z-index: 1;
                 overflow: hidden;    
                 img{
                 overflow: hidden;    
                 height: 200px;
                 width: 400px;
 
                 @include mobile{
                     width: 350px;
                 }
 
                 }
             }
 
             &:hover{
                 background-color: $mainColor;
                 img{
                     opacity: 0.2;
                 }
             }
      
        }
 
       
 
     }


    .arrow{
        a{
            position: absolute;
            top:293%;
            left: 49%;
            img{
                width: 30px;
                animation: arrowBlink 2s infinite;
            }
    
    
            @keyframes arrowBlink{
                100%{
                    opacity: 0;
                }
    
            }
        }
    }
  } 

   


